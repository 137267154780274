import React, { useContext, useEffect, useState } from 'react';
import '../Sidebar.css';
import { NavLink, Outlet } from 'react-router-dom';
import { GlobalContext, GlobalProvider } from '../../context/GlobalState';
import ProfileSummary from '../growth-enabler/profile/ProfileSummary';
import Loader from '../../utils/Loader';

const ManagerPageLayout = () => {
  const { loggedInUser, getUserById } = useContext(GlobalContext); // Access the `getUserById` function
  const [userDetails, setUserDetails] = useState(null); // State to store user data
  const [loading, setLoading] = useState(true); // State to handle loading

  // Get logged-in user's ID from localStorage
  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);
  const userId = userObj._id; // Assuming `_id` is the user's ID

  useEffect(() => {
    if (!loggedInUser || loggedInUser._id !== userId) {
        getUserById(userId)
            .then((user) => {
                setUserDetails(user);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
                setLoading(false);
            });
    } else {
        setUserDetails(loggedInUser);
        setLoading(false);
    }
  }, [loggedInUser, userId, getUserById]);

  if (loading) {
    return <Loader />; // Show a loading state
  }

  return (
    <GlobalProvider >
      <div className="profile-layout">
        <ProfileSummary loggedInUser={userDetails} /> {/* Pass user details to ProfileSummary */}
        <div className="details-container">
          <div className="navbar">
            <NavLink
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              to="/manager-panel/diary-manager-review"
              style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
            >
              <span className="nav-icon material-symbols-rounded">{"reviews"}</span>
              <span className="nav-label" style={{ width: 'fit-content' }}>Team Growth Diary</span>
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              to="/manager-panel/time-off-review"
              style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
            >
              <span className="nav-icon material-symbols-rounded">{"timer"}</span>
              <span className="nav-label" style={{ width: 'fit-content' }}>Time-off Review</span>
            </NavLink>
          </div>
          {/* Pass userDetails to Outlet */}
          <Outlet context={{ userDetails }} />
        </div>
      </div>
    </GlobalProvider>
  );
};

export default ManagerPageLayout;