import React from 'react';
import { useOutletContext } from 'react-router-dom';
import '../../../App.css';
import HabitCalendar from '../habit-tracker/HabitCalendar';

function HabitTracker() {

  const { userDetails } = useOutletContext();

  // const userInfo = window.localStorage.getItem('user');
  // const userObj = JSON.parse(userInfo);
  
  return (
    // <GlobalProvider>
        <HabitCalendar user={userDetails}/>
    // </GlobalProvider>
  )
}

export default HabitTracker
