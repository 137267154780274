import React, { useState } from 'react';
import '../../App.css';
import { GlobalProvider } from '../../context/GlobalState';
import EmployeeSubmission from './EmployeeSubmission';
import SubmissionsList from './SubmissionsList';

function GrowthTracker() {
  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);

  const [isInputFormOpen, setIsInputFormOpen] = useState(false); // Sidebar state
  const [selectedUser, setSelectedUser] = useState(null); // Selected user state

  const handleViewMore = (user) => {
    setSelectedUser(user); // Set the selected user
    setIsInputFormOpen(true); // Open the sidebar
  };

  const closeSidebar = () => {
    setIsInputFormOpen(false); // Close the sidebar
    setSelectedUser(null); // Clear the selected user
  };
  
  return (
    // <GlobalProvider>
        <div style={{display: 'flex', justifyContent: 'start', margin: '2rem'}}>
            <SubmissionsList isInputFormOpen={isInputFormOpen} />
            {/* <div className='accordion'>
                <details>
                <summary style={{margin: '1rem', fontSize: '1.25rem', padding: '0.3rem 0.7rem'}}>+</summary>
                    <EmployeeSubmission user={userObj}/>
                </details>
            </div> */}
            <div>
              <button
                onClick={() => handleViewMore(userObj)}
                className="add-new-btn material-symbols-rounded"
                >
                {"add"}
              </button>
              {isInputFormOpen && (
                <div className="detail-sidebar">
                  <div className="detail-sidebar-content">
                    <button className="close-btn" onClick={closeSidebar}>
                      <span className="material-symbols-rounded">
                        {"close"}
                      </span>
                    </button>
                    {selectedUser && (
                      <>
                        <EmployeeSubmission user={selectedUser} />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
        </div>
    // </GlobalProvider>
  )
}

export default GrowthTracker
