import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalState";
import "./UserList.css";
import { timeFormatted } from "../../utils/DateFormat";
import Timeline from "../growth-enabler/profile/Timeline";
import ProfileEdit from "../growth-enabler/profile/ProfileEdit";
import ProfileMainEdit from "../growth-enabler/profile/ProfileMainEdit";

const UserList = ({ user, userRole, isSidebarOpen, setIsSidebarOpen }) => {
  const { users, getUsers } = useContext(GlobalContext);
  const [selectedUser, setSelectedUser] = useState(null); // Track the selected user
  const [expandedRow, setExpandedRow] = useState(null); // Track expanded row
  const [isEditOpen, setIsEditOpen] = useState(false); // New state for edit

  console.log(selectedUser);
  console.log(userRole);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleViewMore = (user) => {
    setSelectedUser(user); // Set the selected user
    setIsSidebarOpen(true); // Open the sidebar
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false); // Close the sidebar
    setSelectedUser(null); // Clear the selected user
  };

  // Toggle row expansion for timeline
  const toggleRowExpansion = (userId) => {
    setExpandedRow(expandedRow === userId ? null : userId);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsEditOpen(true); // Open edit sidebar
  };

  const closeEditSidebar = () => {
    setIsEditOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className={"limiter"}>
      <div className="wrap-table100">
        <div className="table100">
          <table className={isSidebarOpen ? "sidebar-table-responsive" : ""}>
            <thead>
              <tr className="table100-head">
                <th className="column0"></th>
                <th className="column1">First Name</th>
                <th className="column2">Last Name</th>
                <th className="column3">Mobile</th>
                <th className="column4">Account Email</th>
                <th className="column5">Designation</th>
                <th className="column6">Team</th>
                <th className="column7">Department</th>
                <th className="column8">Type</th>
                <th className="column9"></th>
              </tr>
            </thead>
            <tbody>
              {/* {users.map((val, key) => ( */}
              {users.map((user, key) => {
                // Extract user's contract milestones
                const milestones = [
                  { name: "Internship Start", date: user.contractMilestones?.internship?.startDate },
                  { name: "Collaboration Start", date: user.contractMilestones?.collaboration?.collaborationDate },
                  { name: "Probation Start", date: user.contractMilestones?.probation?.probationDate },
                  { name: "Employment Start", date: user.contractMilestones?.empContract1?.contractDate1 },
                  { name: "Renewal #1", date: user.contractMilestones?.empContract2?.contractDate2 },
                  { name: "Renewal #2", date: user.contractMilestones?.empContract3?.contractDate3 },
                ].filter(m => m.date); // Remove null values

                return (
                  <React.Fragment key={key}>
                    <tr>
                      <td className="column0">
                        <button
                          onClick={() => toggleRowExpansion(user._id)}
                          className="view-more-btn material-symbols-rounded"
                        >
                          {expandedRow === user._id ? "expand_less" : "expand_more"}
                        </button>
                      </td>
                      <td className="column1"><b>{user.firstName}</b></td>
                      <td className="column2">{user.lastName}</td>
                      <td className="column3">{user.contact.mobile}</td>
                      <td className="column4">{user.email}</td>
                      <td className="column5">{user.workInfo.designation}</td>
                      <td className="column6">{user.team}</td>
                      <td className="column7">{user.workInfo.department}</td>
                      <td className="column8">{user.type}</td>
                      <td className="column9">
                        <button onClick={() => handleEdit(user)} style={{ width: "fit-content", backgroundColor: "transparent", border: "none" }}>
                          <ProfileEdit selectedUser={selectedUser} user={user} userRole={userRole} setState={setSelectedUser}/>
                        </button>
                        <button
                          onClick={() => handleViewMore(user)}
                          className="view-more-btn material-symbols-rounded"
                        >
                          {"more_horiz"}
                        </button>
                      </td>
                    </tr>
                    {expandedRow === user._id && (
                      <tr style={{ height: "10rem" }}>
                        <td colSpan="10" style={{ width: "50%", justifyContent: "flex-end" }}>
                          <div style={{ width: "100%" }}>
                            <Timeline milestones={milestones} />
                          </div>
                        </td>
                      </tr>
                    )}
                    </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Sidebar for View More */}
      {isSidebarOpen && selectedUser && (
        <div className="detail-sidebar">
          <div className="detail-sidebar-content">
            <button className="close-btn" style={{ position: "sticky", top: "10px", right: "10px" }} onClick={closeSidebar}>
              <span className="material-symbols-rounded">
                {"close"}
              </span>
            </button>
            {selectedUser && (
              <>
                <h2>User Details</h2>
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>First Name:</strong> {selectedUser.firstName}</p>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Last Name:</strong> {selectedUser.lastName}</p>
                </span>
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Account Type:</strong> {selectedUser.type}</p>
                </span>
                <hr style={{ margin: "1rem 0", border: "1px solid #ccc" }} />
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Designation:</strong> {selectedUser.workInfo.designation}</p>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Department:</strong> {selectedUser.workInfo.department}</p>
                </span>
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Role:</strong> {selectedUser.workInfo.role}</p>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Team:</strong> {selectedUser.team}</p>
                </span>
                <hr style={{ margin: "1rem 0", border: "1px solid #ccc" }} />
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Mobile:</strong> {selectedUser.contact.mobile}</p>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Email:</strong> {selectedUser.email}</p>
                </span>
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ padding: "0 1rem" }}><strong>Date of Birth:</strong> {timeFormatted(selectedUser.personalInfo.dob)}</p>
                </span>
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ padding: "0 1rem" }}><strong>Current Address:</strong> {selectedUser.personalInfo.currentAddress}</p>
                </span>
                <hr style={{ margin: "1rem 0", border: "1px solid #ccc" }} />
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Id Card No.:</strong> {selectedUser.personalInfo.idCardNo}</p>
                  <p style={{ width: "calc(100% / 2)", padding: "0 1rem" }}><strong>Id Card Date:</strong> {timeFormatted(selectedUser.personalInfo.idCardDate)}</p>
                </span>
                <span style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "start" }}>
                  <p style={{ padding: "0 1rem" }}><strong>Id Card Address:</strong> {selectedUser.personalInfo.idCardAddress}</p>
                </span>
              </>
            )}
          </div>
        </div>
      )}
      {/* {isEditOpen && selectedUser && (
        <div className="detail-sidebar">
          <div className="edit-sidebar-content">
        <button className="close-btn" onClick={closeEditSidebar}>
        <span className="material-symbols-rounded">{"close"}</span>
        </button>
        <h2>Edit User</h2>
        <ProfileMainEdit user={selectedUser} state={selectedUser} setState={setSelectedUser} />
        </div>
        </div>
      )} */}
    </div>
  );
};

export default UserList;