import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import LeaveRequestForm from "./LeaveRequestForm";
import { GlobalContext } from "../../context/GlobalState";
import { useOutletContext } from "react-router-dom";
import CalHeatmap from 'cal-heatmap';
import 'cal-heatmap/cal-heatmap.css';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import LegendLite from 'cal-heatmap/plugins/LegendLite';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import { timeFormatted } from '../../utils/DateFormat';
import { io } from "socket.io-client";

const socket = io('http://localhost:3001');
const LeaveTracker = () => {

  const { userDetails } = useOutletContext();
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [isInputFormOpen, setIsInputFormOpen] = useState(false); // Sidebar state
  const formRef = useRef(null);
  const [heatmapData, setHeatmapData] = useState([]);

  useEffect(() => {
    fetchLeaveRequests();

    // ✅ Register user with socket
    // socket.emit("registerUser", userDetails._id);

    // ✅ Listen for new leave requests
    socket.on("sync-leave-request", (newLeave) => {
        setLeaveRequests((prev) => [newLeave, ...prev]);
    });

    // ✅ Listen for status updates
    socket.on("sync-leave-status", (updatedLeave) => {
        setLeaveRequests((prev) =>
            prev.map((leave) =>
                leave._id === updatedLeave._id ? updatedLeave : leave
            )
        );
    });

    return () => {
        socket.off("sync-leave-request");
        socket.off("sync-leave-status");
    };
  }, []);

  const fetchLeaveRequests = async () => {
      try {
          const token = `${userDetails.email}+${userDetails.password}`
          const config = {
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          }
          const res = await axios.get(`/api/v1/leaves/user/${userDetails._id}`, config);
          const sortedRequests = res.data.sort((a, b) => 
            new Date(b.createdAt) - new Date(a.createdAt) 
          );
          setLeaveRequests(sortedRequests);

          const formattedLeaveData = transformLeaveData(sortedRequests);
          setHeatmapData(formattedLeaveData);

      } catch (error) {
        console.error("Error fetching leave requests:", error);
      }
  };

  const handleAddNew = () => {
      setIsInputFormOpen(true); // Open the sidebar
  };
    
  const closeSidebar = () => {
      setIsInputFormOpen(false); // Close the sidebar
  };

    // 🔹 Close form when clicking outside
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setIsInputFormOpen(false);
    }
  };

  useEffect(() => {
    if (isInputFormOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isInputFormOpen]);

  const cancelLeave = async (leaveId) => {
    try {
      const token = `${userDetails.email}+${userDetails.password}`;
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };
  
      await axios.put(`/api/v1/leaves/${leaveId}/cancel`, {}, config);
      
      alert("Leave request canceled successfully.");
      fetchLeaveRequests(); // Refresh the list
    } catch (error) {
      console.error("Error canceling leave request:", error);
      alert("Failed to cancel leave request.");
    }
  };

  const transformLeaveData = (leaveRequests) => {
    if (leaveRequests.length === 0) return []; // No leave requests
  
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today
  
    // Extract all leave request dates
    let allLeaveDates = leaveRequests.flatMap((leave) =>
      leave.leaveDates.map((dateObj) => new Date(dateObj.date))
    );
  
    // Determine the oldest leave request date
    const minLeaveDate = new Date(Math.min(...allLeaveDates.map((d) => d.getTime())));
    minLeaveDate.setHours(0, 0, 0, 0);
  
    let leaveMap = new Map();
  
    // Store actual leave request statuses in the leave map
    leaveRequests.forEach((leave) => {
      leave.leaveDates.forEach((dateObj) => {
        const formattedDate = new Date(dateObj.date).toLocaleDateString("en-CA");
        
        if (dateObj.status === "Pending") {
          leaveMap.set(formattedDate, "pending");
        } else if (dateObj.status === "Approved") {
          leaveMap.set(formattedDate, 
            leave.leaveType === "Work From Home" ? "WFH" :
            ["Vacation", "Sick Leave", "Maternity Leave", "Paternity Leave", "Marriage Leave", "Bereavement Leave", "Other", "Unpaid Leave"].includes(leave.leaveType)
            ? "off" : "on"
          );
        } else if (dateObj.status === "Rejected") {
          leaveMap.set(formattedDate, "");
        }
      });
    });
  
    // Generate the full date range from minLeaveDate → today
    let fullDateRange = [];
    for (let d = new Date(minLeaveDate); d <= today; d.setDate(d.getDate() + 1)) {
      let dateStr = new Date(d).toLocaleDateString("en-CA");
      fullDateRange.push({
        date: dateStr,
        status: leaveMap.has(dateStr) ? leaveMap.get(dateStr) : "on" // Default to "on"
      });
    }
  
    // Include future leave requests only
    let futureRequests = leaveRequests.flatMap((leave) =>
      leave.leaveDates
        .filter((dateObj) => new Date(dateObj.date) > today)
        .map((dateObj) => ({
          date: new Date(dateObj.date).toLocaleDateString("en-CA"),
          status: leaveMap.get(new Date(dateObj.date).toLocaleDateString("en-CA")),
        }))
    );
  
    return [...fullDateRange, ...futureRequests];
  };

  const calHeatmapContainer = useRef(null);
  const calInstance = useRef(null);

  useEffect(() => {
    // Initialize CalHeatmap
    const cal = new CalHeatmap();

    calInstance.current = cal;

    try {
      cal.paint(
        {
          itemSelector: calHeatmapContainer.current,
          data: {
            source: heatmapData,
            type: 'json',
            x: 'date',
            y: (d) => {
              if (d["status"] === "off") return 0;  // Day off
              if (d["status"] === "on") return 1;   // On-site work
              if (d["status"] === "WFH") return 2;  // Work From Home
              if (d["status"] === "pending") return 3;  // Pending
            }
          },
          date: { start: new Date('2025-01-01') },
          range: 12,
          scale: {
            color: {
              type: 'threshold',
              range: ['#fff', '#032A49', '#4dd05a', '#616E9A', '#FFC107'],
              domain: [0, 1, 2, 3],
            },
          },
          domain: {
            type: 'month',
            gutter: 4,
            label: { text: 'MMM', textAlign: 'start', position: 'top' },
          },
          subDomain: { type: 'day', radius: 2, width: 11, height: 11, gutter: 4 },
        },
        [
          [Tooltip, { text: (date, value) => 
            `${timeFormatted(date).split('T')[0]}${
            value === 0 ? ': Off' : value === 1 ? ': On-site' : value === 2 ? ': WFH' : value === 3 ? ': Pending' : ''
            }` }],
          // [LegendLite, { includeBlank: true }],
          [
            CalendarLabel, {
              width: 30,
              textAlign: 'start',
              text: () => ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              padding: [25, 0, 0, 0],
            },
          ],
        ]
      );
    } catch (err) {
      console.error("CalHeatmap initialization error:", err);
    }

    return () => {
      cal.destroy();
    };
  }, [leaveRequests]);

  return (
    <div className="leave-tracker-container">
      <h2>Leave & Work From Home Schedule</h2>

      {/* Leave Request Form */}
      <button
          onClick={() => handleAddNew()}
          className="add-new-btn material-symbols-rounded"
      >
          {"add"}
      </button>
      {isInputFormOpen && (
        <div ref={formRef} className="detail-sidebar" style={{ width: "20vw" }}>
          <div className="detail-sidebar-content">
            <button className="close-btn" onClick={closeSidebar}>
              <span className="material-symbols-rounded">
                {"close"}
              </span>
            </button>
              <LeaveRequestForm user={userDetails} onLeaveSubmit={fetchLeaveRequests} closeForm={closeSidebar} />
          </div>
        </div>
      )}

      <div className='cal-heatmap-container'>
        <div ref={calHeatmapContainer} style={{ margin: '20px auto' }}></div>
        <div style={{ display: 'inline-flex' }}>
          <button className='btn' style={{ marginRight: '10px', fontSize: '1rem' }} onClick={() => calInstance.current.previous()}>←</button>
          <button className='btn' style={{ marginRight: '10px', fontSize: '1rem' }} onClick={() => calInstance.current.next()}>→</button>
        </div>
      </div>

      {/* Leave History Table */}
      <h3>My Leave & Work Form Home Request History</h3>
      <div className="leave-table-container">
        <table className="leave-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Dates</th>
              <th>Reason</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {leaveRequests.length > 0 ? (
              leaveRequests.map((leave) => (
                <tr key={leave._id}>
                  <td>{leave.leaveType}</td>
                  <td>
                    {leave.leaveDates.map((dateObj) => (
                      <span key={dateObj.date}>
                        {new Date(dateObj.date).toLocaleDateString()}{" "}
                        {dateObj.isHalfDay && "(Half Day)"} <br />
                      </span>
                    ))}
                  </td>
                  <td>{leave.reason || "N/A"}</td>
                  <td className={`status-${leave.status.toLowerCase()}`}>{leave.status}</td>
                  <td>
                    {leave.status === "Pending" && (
                      <button className="cancel-btn" onClick={() => cancelLeave(leave._id)}>
                        Cancel
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No leave records found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaveTracker;