import React, { useContext, useEffect, useState } from 'react';
import '../../Sidebar.css';
import { NavLink, Outlet } from 'react-router-dom';
import { GlobalContext, GlobalProvider } from '../../../context/GlobalState';
import ProfileSummary from './ProfileSummary';
import Loader from '../../../utils/Loader';

const ProfileLayout = () => {
  const { loggedInUser, getUserById } = useContext(GlobalContext); // Access the `getUserById` function
  const [userDetails, setUserDetails] = useState(null); // State to store user data
  const [loading, setLoading] = useState(true); // State to handle loading

  // Get logged-in user's ID from localStorage
  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);
  const userId = userObj._id; // Assuming `_id` is the user's ID

  useEffect(() => {
    if (!loggedInUser || loggedInUser._id !== userId) {
        getUserById(userId)
            .then((user) => {
                setUserDetails(user);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
                setLoading(false);
            });
    } else {
        setUserDetails(loggedInUser);
        setLoading(false);
    }
  }, [loggedInUser, userId, getUserById]);

  if (loading) {
    return <Loader />; // Show a loading state
  }

  return (
    <GlobalProvider >
      <div className="profile-layout">
        <ProfileSummary loggedInUser={userDetails} /> {/* Pass user details to ProfileSummary */}
        <div className="details-container">
          <div className="navbar">
            <NavLink
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              to="/member-view/profile"
              style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
            >
              <span className="nav-icon material-symbols-rounded">{"account_circle"}</span>
              <span className="nav-label" style={{ width: 'fit-content' }}>Your Profile</span>
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              to="/member-view/time-off"
              style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
            >
              <span className="nav-icon material-symbols-rounded">{"timer"}</span>
              <span className="nav-label" style={{ width: 'fit-content' }}>Time-off</span>
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              to="/member-view/tickets"
              style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
            >
              <span className="nav-icon material-symbols-rounded">{"edit_document"}</span>
              <span className="nav-label" style={{ width: 'fit-content' }}>Support Ticket</span>
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              to="/member-view/habit"
              style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
            >
              <span className="nav-icon material-symbols-rounded">{"event_available"}</span>
              <span className="nav-label" style={{ width: 'fit-content' }}>Habit Tracker</span>
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              to="/member-view/poket-pet"
              style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
            >
              <span className="nav-icon material-symbols-rounded">{"pets"}</span>
              <span className="nav-label" style={{ width: 'fit-content' }}>Pet Garden</span>
            </NavLink>
            {/* <div style={{display: 'inline-flex', margin: 0}}>
              <div id='scroll-container'>
                <h4 id='scroll-text'>
                  <i>
                  Good day to <em style={{color: '#FFC107'}}>{loggedInUser.firstName} {loggedInUser.lastName}</em>!
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  ...
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Let's uplift your day with a wise word from &nbsp;
                    <QuoteList />
                  </i>
                </h4>
              </div>
            </div> */}
          </div>
          {/* Pass userDetails to Outlet */}
          <Outlet context={{ userDetails }} />
        </div>
      </div>
    </GlobalProvider>
  );
};

export default ProfileLayout;