import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./LeaveTracker.css";

const LeaveRequestForm = ({ user, onLeaveSubmit, closeForm }) => {
    const [leaveType, setLeaveType] = useState("");
    const [leaveDates, setLeaveDates] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]); // Stores start & end dates
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const token = localStorage.getItem("token");

    // Available leave types
    const leaveTypes = [
        "Vacation", "Sick Leave", "Work From Home", "Unpaid Leave", "Maternity Leave",
        "Paternity Leave", "Marriage Leave", "Bereavement Leave", "Other"
    ];

    // Handle date selection (supports single & range selection)
    const handleDateChange = (update) => {
        setDateRange(update);
        if (update[0] && !update[1]) {
            generateLeaveDates(update[0], update[0]); // Single date selected
        } else if (update[0] && update[1]) {
            generateLeaveDates(update[0], update[1]); // Range selected
        }
    };

    // Generate leave dates from start to end date
    const generateLeaveDates = (start, end) => {
        let currentDate = new Date(start);
        let endDate = new Date(end);
        let newDates = [];

        while (currentDate <= endDate) {
            newDates.push({ date: new Date(currentDate), isHalfDay: false, status: "Pending" });
            currentDate.setDate(currentDate.getDate() + 1);
        }

        setLeaveDates(newDates);
    };

    // Toggle half-day leave option
    const toggleHalfDay = (index) => {
        const updatedDates = [...leaveDates];
        updatedDates[index].isHalfDay = !updatedDates[index].isHalfDay;
        setLeaveDates(updatedDates);
    };

    // Remove a selected date
    const removeDate = (index) => {
        setLeaveDates(leaveDates.filter((_, i) => i !== index));
    };

    // Calculate total leave days
    const calculateTotalDays = (leaveDates) => {
        return leaveDates.reduce((total, day) => total + (day.isHalfDay ? 0.5 : 1), 0);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!leaveType || leaveDates.length === 0) {
            setErrorMessage("Please select a leave type and a valid date.");
            return;
        }

        setLoading(true);
        const requestData = {
            user: user._id,
            leaveType,
            leaveDates,
            totalDays: calculateTotalDays(leaveDates),
            reason,
        };

        try {
            const token = `${user.email}+${user.password}`;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            await axios.post("/api/v1/leaves", requestData, config);

            setSuccessMessage("Leave request submitted successfully!");
            setLeaveType("");
            setLeaveDates([]);
            setDateRange([null, null]); // Reset date range
            setReason("");

            if (typeof onLeaveSubmit === "function") {
                onLeaveSubmit();
            }
        } catch (error) {
            console.error("Error submitting leave request:", error);
            setErrorMessage("Please provide reason for your office absence.");
        } finally {
            setLoading(false);
        }
    };

    const closeSuccessMessage = () => {
      setSuccessMessage(""); 
      closeForm();  // Close the form when success message is closed
    };

    return (
        <div className="leave-form-container">
            <h2>Request Leave</h2>
            <form onSubmit={handleSubmit}>
                {/* Leave Type Selection */}
                <label>Leave Type:</label>
                <select value={leaveType} onChange={(e) => setLeaveType(e.target.value)} required>
                    <option value="">Select Leave Type</option>
                    {leaveTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                    ))}
                </select>

                {/* Date Picker (Supports Single & Range) */}
                <label>Select Leave Date (Single or Range):</label>
                <DatePicker
                    selectsRange
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={handleDateChange}
                    dateFormat="MMM dd, yyyy"
                    minDate={new Date()}
                    className="date-picker"
                    placeholderText="Select a date or range"
                />

                {/* Display Selected Leave Dates */}
                {leaveDates.length > 0 && (
                    <ul className="leave-dates-list">
                        {leaveDates.map((entry, index) => (
                            <li key={index} style={{ justifyContent: 'space-between' }}>
                                {entry.date.toLocaleDateString()} 
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div className="tooltip-container">
                                    <span className="tooltip">
                                        {entry.isHalfDay ? "Half-day Enabled" : "Half-day Disabled"}
                                    </span>
                                    <label className="switch">
                                        <input 
                                            type="checkbox" 
                                            checked={entry.isHalfDay} 
                                            onChange={() => toggleHalfDay(index)} 
                                        />
                                        <span className="slider"></span>
                                    </label>
                                  </div>
                                  <div className="tooltip-container">
                                    <p className="tooltip">
                                          Remove
                                    </p>
                                    <button className="close-btn" style={{ margin: '0' }} type="button" onClick={() => removeDate(index)}>
                                      <span className="material-symbols-rounded">
                                        {"close"}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}

                {/* Reason for Leave */}
                <label>Reason:</label>
                <textarea value={reason} onChange={(e) => setReason(e.target.value)} rows="3"></textarea>

                {/* Submit Button */}
                <button className="btn" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                </button>
            </form>

            {/* Error Message */}
            {errorMessage && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="material-symbols-rounded" style={{ fontSize: '2rem', color: '#D2042D' }}>
                            {"error"}
                        </span>
                        <p>{errorMessage}</p>
                        <button onClick={() => setErrorMessage("")}>Close</button>
                    </div>
                </div>
            )}

            {/* Success Message */}
            {successMessage && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="material-symbols-rounded" style={{ fontSize: '2rem', color: '#2ecc71' }}>
                            {"new_releases"}
                        </span>
                        <p>{successMessage}</p>
                        <button onClick={() => setSuccessMessage("")}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LeaveRequestForm;