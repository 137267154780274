import React from "react";
import { timeFormatted } from '../../../utils/DateFormat';

const Timeline = ({ milestones }) => {
  const today = new Date();
  const startDate = new Date(Math.min(...milestones.map(m => new Date(m.date))));
  const endDate = new Date(Math.max(...milestones.map(m => new Date(m.date))));
  
  // Calculate progress percentage (from start to today)
  const totalDuration = endDate - startDate;
  const progress = ((today - startDate) / totalDuration) * 100;

  return (
    <div className="timeline-container">
      {/* Background timeline */}
      <div className="timeline-bar">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>

      {/* Milestone points */}
      <div className="milestones">
        {milestones.map((milestone, index) => {
          const position =
            ((new Date(milestone.date) - startDate) / totalDuration) * 100;
          const isPast = new Date(milestone.date) <= today;
          const isEmploymentStart = milestone.name === "Employment Start" | milestone.name === "Internship Start";

          return (
            <div
              key={index}
              className={`milestone ${isPast ? "past" : "future"}`}
              style={{ left: `${position}%` }}
            >
              <span className="dot"></span>
              <span className={`label ${isEmploymentStart ? "above-text" : ""}`}>
                {milestone.name}
                <br />
                {timeFormatted(milestone.date)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;