import React from "react";
import styled from "styled-components";
import ServiceTask from "./ServiceTask";
import { Droppable } from "react-beautiful-dnd";

const Container = styled.div`
  background-color: #f7f7f7;
  width: 22vw;
  height: 80vh;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 0.5vw;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  padding: 8px;
  background-color: #032A49;
  border-radius: 0.5rem 0.5rem 0 0;
  text-align: center;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const TaskListWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto; /* Apply scrolling only to tasks */
  padding: 1rem;
  
  /* Ensure scrollbar is behind Title */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

const ServiceColumn = ({ title, tasks, id, members }) => {
  return (
    <Container>
      <Title>{title} ({tasks.length})</Title>
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <TaskListWrapper
            ref={provided.innerRef}
            {...provided.droppableProps}
            isdraggingover={snapshot.isDraggingOver}
          >
            {tasks.map((task, index) => (
              <ServiceTask key={index} index={index} task={task} members={members} />
            ))}
            {provided.placeholder}
          </TaskListWrapper>
        )}
      </Droppable>
    </Container>
  );
}

export default ServiceColumn;