import React from 'react';
import '../../../App.css';
import { GlobalProvider } from '../../../context/GlobalState';
import AddTicket from './AddTicket';
import { TaskProvider } from '../TaskState';

function ServiceTicket() {
  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);
  
  return (
    // <GlobalProvider>
      <TaskProvider>
        <h2 style={{ width: '100%', margin: '1vw 2vw', margin: '1rem auto', textAlign: 'center'}}>
          Hi <b style={{color:'#FFC107'}}>{`${userObj.firstName} ${userObj.lastName}`}</b>! How can we help YOU?
        </h2>
        <AddTicket />
      </TaskProvider>
    // </GlobalProvider>
  )
}

export default ServiceTicket
