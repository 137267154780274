import React from 'react';
import Header from '../Header';
import { GlobalProvider } from '../../context/GlobalState';
import { ServiceProvider } from '../service-center/ServiceState';
import LunchList from './LunchList';
import LunchOrdered from './LunchOrdered';

function LunchOrder() {
  return (
    // <GlobalProvider>
    //     <Header />
        <ServiceProvider>
            <LunchOrdered />
            <br/>
            <LunchList />
        </ServiceProvider>
    // </GlobalProvider>

  )
}

export default LunchOrder
