import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>);

// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import App from './App';
// import { BrowserRouter } from 'react-router-dom';

// // Ensure `container` is not null by using a type assertion (`as HTMLElement`)
// const container = document.getElementById('root') as HTMLElement;
// const root = createRoot(container);

// root.render(
//     <BrowserRouter>
//         <App />
//     </BrowserRouter>
// );