import React, { useEffect, useRef } from 'react';
import Header from './Header';
import { GlobalProvider } from '../context/GlobalState';
import RollingText from './RollingText';
import CalHeatmap from 'cal-heatmap';
import 'cal-heatmap/cal-heatmap.css';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import LegendLite from 'cal-heatmap/plugins/LegendLite';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import { timeFormatted } from '../utils/DateFormat';

function PinBoard() {

  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);;
  const arr = userObj.firstName.toUpperCase().split('');

  const today = new Date();
  const currentDay = today.toLocaleDateString('en-US', { weekday: 'long' });

  return (
    <div>
      <h1 style={{textAlign: 'center', color: '#032A49', letterSpacing: '2px'}}>
        <br/>
        Happy {currentDay}, {userObj.firstName}!
        <br/>
        🌟🌟🌟
        <br/>
        <br/>
        Your Amazing Traits Will Help You Shine Today
      </h1>
      <h1 className='h1-text'>
        {arr.map(char => (<RollingText key = {Math.floor(Math.random() * 100000000)} firstNameChar = {char}/>))}
      </h1>
    </div>
  )
}

export default PinBoard
