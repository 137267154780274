import React, { useRef, useState, useEffect} from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

import { timeFormatted } from '../../../utils/DateFormat';

const initialValue = dayjs(new Date());

function ServerDay(props) {
    const { badge, highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
        !props.outsideCurrentMonth && highlightedDays.indexOf(timeFormatted(props.day.$d.toLocaleDateString())) >= 0;

    return (
        <div>
            <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? props.badge : undefined}
            >
            <PickersDay sx={isSelected ? { backgroundColor: '#032A49', color: '#fff' } : {}} {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        </div>
    );
}

const HabitCalendarItem = ({user, habit}) => {
    
    const requestAbortController = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [value] = useState(initialValue);
    const [badge, setBadge] = useState('');
  
    const fetchHighlightedDays = () => {
        const controller = new AbortController();
        const convertedArr = []
        habit.progress.forEach( d => convertedArr.push(timeFormatted(d)) );
        setHighlightedDays(convertedArr);
        setBadge(habit.badge);
        setIsLoading(false);
        
        requestAbortController.current = controller;
    };
  
    useEffect(() => {

        fetchHighlightedDays(initialValue);
        // abort request on unmount
      return () => requestAbortController.current?.abort();
        // eslint-disable-next-line
    }, []);
  
    const handleMonthChange = (date) => {
        if (requestAbortController.current) {
            // make sure that you are aborting useless requests
            // because it is possible to switch between months pretty quickly
            requestAbortController.current.abort();
        }
    };
  
    const clickDate = (user, date) => {
        const token = `${user.email}+${user.password}`
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        const update = [timeFormatted(date.$d)];

        const d = new Date (Date.now());
        const currentDate = new Date(d.setUTCHours(0, 0, 0, 0));
        const selectedDate = new Date(date.$d.setUTCHours(0, 0, 0, 0));
        if (selectedDate > currentDate) {
            alert("The day has not come yet!")
        } else {
            axios
            .put(`/api/v1/habits/${habit._id}`, update, config)
            .then((res) => {
                const convertedArr = []
                res.data.data.progress.forEach( d => convertedArr.push(timeFormatted(d)) );
                setHighlightedDays(convertedArr);
                alert('🎉 You’re on the right track now. Keep it up!')
            })
            .catch((err) => {
                console.error(err);
                if (err.response.status === 409) {
                    alert(err.response.data.error)
                };
            });
        }
    }

    function findStreak(dates) {        
        if (dates.length === 0) {
          return 0;
        }

        const convertedArr = [];

        for (let i = 0; i < dates.length; i++) {
            const date = new Date(dates[i]);
            convertedArr.push(date);
        }

        convertedArr.sort((a, b) => a - b);

        let currentStreak = 1;
        let longestStreak = 1;
      
        for (let i = 1; i < convertedArr.length; i++) {
            
            const timeDiff = convertedArr[i].getTime() - convertedArr[i-1].getTime();
        
            if (timeDiff === 86400000) { // 86400000 milliseconds in a day
                currentStreak++;
                longestStreak = Math.max(longestStreak, currentStreak);
            } else if (timeDiff > 86400000) {
                currentStreak = 1;
            }
        }
      
        return longestStreak;
    }

    return (
        <div className='habit-container'>
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
                <h4>⚡ &nbsp;&nbsp; {highlightedDays.length}</h4>
                <span style={{ width: '1.5rem' }}></span>
                <h4>🔥 &nbsp;&nbsp; {findStreak(highlightedDays)}</h4>
                <span style={{ width: '0.5rem' }}></span>
            </div>
            <hr/>
            <h1>{habit.habitName}</h1>
            <h4 style={{ height: '2rem', margin: '0.5rem 0' }}>{habit.description}</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                displayWeekNumber
                defaultValue={initialValue}
                value={value} onChange={(newValue) => clickDate(user, newValue)}
                loading={isLoading}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                    day: ServerDay,
                }}
                slotProps={{
                    day: {
                    highlightedDays,
                    badge,
                    },
                }}
                />
            </LocalizationProvider>
        </div>
    );
}

export default HabitCalendarItem
