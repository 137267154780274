import React from 'react';
import { timeFormatted } from '../../../utils/DateFormat';
import ProfileEdit from './ProfileEdit';
import { useOutletContext } from 'react-router-dom';
import Timeline from './Timeline';
import "./ProfileTimeline.css";

import CakeIcon from '@mui/icons-material/Cake';
import PlaceIcon from '@mui/icons-material/Place';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import EventIcon from '@mui/icons-material/Event';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Filter2OutlinedIcon from '@mui/icons-material/Filter2Outlined';
import Looks3Icon from '@mui/icons-material/Looks3';
import Filter3OutlinedIcon from '@mui/icons-material/Filter3Outlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ExtensionIcon from '@mui/icons-material/Extension';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Skeleton } from '@mui/material';

const ProfileMain = () => {

    const { userDetails } = useOutletContext();

    // Extract contract milestones into timeline format
    const milestones = [
        userDetails?.contractMilestones?.internship?.startDate && { name: "Internship Start", date: userDetails.contractMilestones.internship.startDate },
        userDetails?.contractMilestones?.collaboration?.collaborationDate && { name: "Collaboration Start", date: userDetails.contractMilestones.collaboration.collaborationDate },
        userDetails?.contractMilestones?.probation?.probationDate && { name: "Probation Start", date: userDetails.contractMilestones.probation.probationDate },
        userDetails?.contractMilestones?.empContract1?.contractDate1 && { name: "Employment Start", date: userDetails.contractMilestones.empContract1.contractDate1 },
        userDetails?.contractMilestones?.empContract2?.contractDate2 && { name: "Renewal #1", date: userDetails.contractMilestones.empContract2.contractDate2 },
        userDetails?.contractMilestones?.empContract3?.contractDate3 && { name: "Renewal #2", date: userDetails.contractMilestones.empContract3.contractDate3 },
    ].filter(Boolean); // Remove undefined entries

    return (
        <div className='profile-details-container'>
            {/* <div style={{ height: '5rem'}}></div> */}
            <div style={{display: "inline-flex", justifyContent: "space-between", width: "100%"}}>
                <b style={{fontSize: '2rem', color: '#032A49', width: '100%'}}>
                    {
                        userDetails.firstName ? (
                            userDetails?.firstName + " " + userDetails?.lastName
                        ) : ( <Skeleton variant="rectangular" />)
                    }
                </b>
                <ProfileEdit userDetails = {userDetails}/>
            </div>
            <br/>
            <div className='profile-section' style={{boxShadow: 'none'}}>
                <div className='profile-part'>
                    <Timeline milestones={milestones} />
                </div>
                <br/>
            </div>
            <div className='profile-section'>
                <h3 style={{marginTop: '1rem'}}>Contact</h3>
                <div className='profile-part'>
                    <PhoneIphoneIcon className='icon-container'/>
                    <span className='profile-span-50'>
                        Mobile 
                        <b>
                            {
                                userDetails?.contact?.mobile ? (
                                    userDetails?.contact?.mobile
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <MailIcon className='icon-container'/>
                    <span className='profile-span-50'>
                        Work Email 
                        <b>
                            {
                                userDetails?.email ? (
                                    userDetails?.email
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ContactMailIcon className='icon-container'/>
                    <span className='profile-span-50'>
                        Personal Email 
                        <b>
                            {
                                userDetails?.contact?.personalEmail ? (
                                    userDetails?.contact?.personalEmail
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
            <div className='profile-section'>
            <h3 style={{marginTop: '1rem'}}>Work Information</h3>
                <div className='profile-part'>
                    <AssignmentIndIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Designation 
                        <b>
                            {
                                userDetails?.workInfo?.designation ? (
                                    userDetails?.workInfo?.designation
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ExtensionIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Role in Team 
                        <b>
                            {
                                userDetails?.workInfo?.role ? (
                                    userDetails?.workInfo?.role
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <GroupIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Team 
                        <b>
                            {
                                userDetails?.team ? (
                                    userDetails?.team
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <GroupsIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Department 
                        <b>
                            {
                                userDetails?.workInfo?.department ? (
                                    userDetails?.workInfo?.department
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
            <div className='profile-section'>
                <h3 style={{marginTop: '1rem'}}>Information For Contract</h3>
                <div className='profile-part'>
                    <CakeIcon className='icon-container'/>
                    <span className='profile-span-33'>
                        Date of birth 
                        <b>
                            {
                                userDetails?.personalInfo?.dob ? (
                                    timeFormatted(userDetails?.personalInfo?.dob)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <PersonPinCircleIcon className='icon-container'/>
                    <span className='profile-span-70'>
                        Current Address 
                        <b>
                            {
                                userDetails?.personalInfo?.currentAddress ? (
                                    userDetails?.personalInfo?.currentAddress
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <RecentActorsIcon className='icon-container'/>
                    <span className='profile-span-33'>
                        ID Card Number 
                        <b>
                            {
                                userDetails?.personalInfo?.idCardNo ? (
                                    userDetails?.personalInfo?.idCardNo
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <PlaceIcon className='icon-container'/>
                    <span className='profile-span-70'>
                        Address on ID card 
                        <b>
                            {
                                userDetails?.personalInfo?.idCardAddress ? (
                                    userDetails?.personalInfo?.idCardAddress
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
            <div className='profile-section'>
                <h3 style={{marginTop: '1rem'}}>Contract Milestones</h3>
                <div className='profile-part'>
                    <CalendarTodayIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Internship Start 
                        <b>
                            {
                                userDetails?.contractMilestones?.internship?.startDate ? (
                                    timeFormatted(userDetails?.contractMilestones?.internship?.startDate)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LocalLibraryIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Internship Contract 
                        <b>
                            {
                                userDetails?.contractMilestones?.internship?.internshipContract ? (
                                    userDetails?.contractMilestones?.internship?.internshipContract
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LocalLibraryOutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                userDetails?.contractMilestones?.internship?.internshipAnnex1 ? (
                                    userDetails?.contractMilestones?.internship?.internshipAnnex1
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <DateRangeIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Collaboration Start 
                        <b>
                            {
                                userDetails?.contractMilestones?.collaboration?.collaborationDate ? (
                                    timeFormatted(userDetails?.contractMilestones?.collaboration?.collaborationDate)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <HandshakeIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Collaboration Contract 
                        <b>
                            {
                                userDetails?.contractMilestones?.collaboration?.collaborationContract ? (
                                    userDetails?.contractMilestones?.collaboration?.collaborationContract
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <HandshakeOutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                userDetails?.contractMilestones?.collaboration?.collaborationAnnex1 ? (
                                    userDetails?.contractMilestones?.collaboration?.collaborationAnnex1
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Probation Start 
                        <b>
                            {
                                userDetails?.contractMilestones?.probation?.probationDate ? (
                                    timeFormatted(userDetails?.contractMilestones?.probation?.probationDate)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ConfirmationNumberIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Probation Contract 
                        <b>
                            {
                                userDetails?.contractMilestones?.probation?.probationContract ? (
                                    userDetails?.contractMilestones?.probation?.probationContract
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ConfirmationNumberOutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                userDetails?.contractMilestones?.probation?.probationAnnex1 ? (
                                    userDetails?.contractMilestones?.probation?.probationAnnex1
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventNoteIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Official Employment Date 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract1?.contractDate1 ? (
                                    timeFormatted(userDetails?.contractMilestones?.empContract1?.contractDate1)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LooksOneIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Contract 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract1?.contract1No ? (
                                    userDetails?.contractMilestones?.empContract1?.contract1No
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Filter1OutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract1?.annex11 ? (
                                    userDetails?.contractMilestones?.empContract1?.annex11
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventRepeatIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Renewal Date 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract2?.contractDate2 ? (
                                    timeFormatted(userDetails?.contractMilestones?.empContract2?.contractDate2)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LooksTwoIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Contract 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract2?.contract2No ? (
                                    userDetails?.contractMilestones?.empContract2?.contract2No
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Filter2OutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract2?.annex21 ? (
                                    userDetails?.contractMilestones?.empContract2?.annex21
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventRepeatIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Renewal Date 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract3?.contractDate3 ? (
                                    timeFormatted(userDetails?.contractMilestones?.empContract3?.contractDate3)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Looks3Icon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Contract 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract3?.contract3No ? (
                                    userDetails?.contractMilestones?.empContract3?.contract3No
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Filter3OutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                userDetails?.contractMilestones?.empContract3?.annex31 ? (
                                    userDetails?.contractMilestones?.empContract3?.annex31
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ProfileMain
