import '../../App.css';
import Header from '../Header';
import Balance from './Balance';
import IncomeExpenses from './IncomeExpenses';
import TransactionList from './TransactionList';
import AddTransation from './AddTransation';
import SearchBar from './SearchBar';
import { GlobalProvider } from '../../context/GlobalState';
import MonthlySummary from './MonthlySummary';
import PersonIncomeExpenses from './PersonIncomeExpenses';
import CatSummary from './CatSummary';

function ExpenseTrackerApp({isAdmin}) {
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);

    return (
        // <GlobalProvider>
        <div>
            <div style={{ height: '4rem'}}></div>
            <div className='container' style={{justifyContent: 'space-between'}}>
                <h2>Team Ledger &nbsp;&nbsp;&nbsp;&nbsp; &#12297;&nbsp;&nbsp;&nbsp;
                    <i style={{textTransform: 'none', fontSize: '1.5rem'}}>{userObj.org}</i>
                </h2>
                <PersonIncomeExpenses />
                <SearchBar />
            </div>
            <div className='container'>
                <div className='subcontainer'>
                    <Balance />
                    <IncomeExpenses />
                    <CatSummary />
                </div>
                <div className='subcontainer'>
                    <MonthlySummary />
                </div>
                <div className='subcontainer'>
                    <AddTransation />
                </div>
            </div>
            <div className='transaction-list'>
                <TransactionList isAdmin={isAdmin} />
            </div>
        </div>
        // </GlobalProvider>
    );
}

export default ExpenseTrackerApp;
