import React, { useState, useEffect, useContext } from "react";
import './App.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from "react-router-dom";
import ExpenseTrackerApp from "./components/expense-tracker/ExpenseTrackerApp";
import PersonalExpenseTrackerApp from "./components/expense-tracker-personal/PersonalExpenseTracker";
import Login from './components/user-management/Login';
import Signup from './components/user-management/Signup';
import PinBoard from './components/PinBoard';
import NewsPocket from './components/NewsPocket';
import LunchAdmin from './components/lunch-order/LunchAdmin';
import LunchOrder from './components/lunch-order/LunchOrder';
import ServiceTicket from './components/service-center/components/ServiceTicket';
import PrivateRoutes from './components/private-route/PrivateRoutes';
import HabitTracker from './components/growth-enabler/objective-tracker/HabitTracker';
import RoleAccess from './components/private-route/RoleAccess';
import AdminPanel from './components/admin-corner/AdminPanel';
import NotFoundPage from './components/private-route/NotFoundPage';
import ServiceBoard from './components/service-center/ServiceBoard';
import Profile from './components/growth-enabler/profile/Profile';
import MainProfile from './components/growth-enabler/profile/ProfileMain';
import SubmissionsList from './components/growth-diary/SubmissionsList';
import GrowthTracker from './components/growth-diary/GrowthTracker';
import Sidebar from './components/Sidebar';
import ManagerReview from "./components/growth-diary/ManagerReview";
import ProfileLayout from "./components/growth-enabler/profile/ProfileLayout";
import { GlobalProvider, GlobalContext } from "./context/GlobalState";
import PoketPetGarden from "./components/growth-enabler/pokepet/PokePetGarden";
import LeaveTracker from "./components/leave-tracker/LeaveTracker";
import ManagerPageLayout from "./components/manager-corner/ManagerPageLayout";
import TeamLeaveRequests from "./components/leave-tracker/TeamLeaveRequests";


function App() {
  const login = window.localStorage.getItem('isLoggedIn');
  const userRole = window.localStorage.getItem('userRole');
  const user = window.localStorage.getItem('user');
  const [sidebarWidth, setSidebarWidth] = useState("5rem"); // Default width when collapsed
  const handleSidebarToggle = (width) => {
    console.log("Sidebar toggled. Current width:", width);
    setSidebarWidth(width);
  };

  const location = useLocation();

  useEffect(() => {
    setSidebarWidth("5rem"); // Reset to collapsed width on navigation
  }, [location]);

  return (
    <GlobalProvider>
          <div className="app-container">
            {login && <Sidebar onToggle={handleSidebarToggle} />}
            <main className="main-content" style={{ marginLeft: login ? sidebarWidth : 0 }}>
              <Routes>
                <Route path="/" element={login ? <PinBoard /> : <Login />}/>
                <Route path="/signup" element={<Signup />}/>
                <Route element={<PrivateRoutes isAuth={login} />}>
                  <Route path="/not-found" element={<NotFoundPage />}/>
                  <Route path="/pin-board" element={<PinBoard />}/>
                  <Route path="/member-view" element={<ProfileLayout />}>
                    <Route path="profile" element={<MainProfile />} />
                    <Route path="tickets" element={<ServiceTicket />} />
                    <Route path="poket-pet" element={<PoketPetGarden />} />
                    <Route path="habit" element={<HabitTracker />}/>
                    <Route path="time-off" element={<LeaveTracker />}/>
                  </Route>
                  <Route path="/service-center/lunch-order" element={<LunchOrder />}/>
                  <Route path="/growth-enabler/diary" element={<GrowthTracker />}/>
                  <Route element={<RoleAccess isAdmin={userRole} />}>
                    <Route path="/manager-panel" element={<ManagerPageLayout />}>
                      <Route path="/manager-panel/diary-manager-review" element={<ManagerReview />}/>
                      <Route path="/manager-panel/time-off-review" element={<TeamLeaveRequests />}/>
                    </Route>
                    <Route path='/expense-tracker/team' element={<ExpenseTrackerApp isAdmin={userRole} />}/>
                    <Route path='/expense-tracker/personal' element={<PersonalExpenseTrackerApp isAdmin={userRole} />}/>
                    <Route path="/service-center/lunch-admin" element={<LunchAdmin />}/>
                    <Route path="/service-center/service-board" element={<ServiceBoard />}/>
                    <Route path='/news-pocket' element={<NewsPocket />}/>
                    <Route path='/admin-panel' element={<AdminPanel userRole={userRole} user={user} />}/>
                  </Route>
                </Route>
              </Routes>
            </main>
          </div>
    </GlobalProvider>

  );
}

export default App;

// import React, { useState, useEffect } from "react";
// import './App.css';
// import './index.css';
// import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// import ExpenseTrackerApp from "./components/expense-tracker/ExpenseTrackerApp";
// import PersonalExpenseTrackerApp from "./components/expense-tracker-personal/PersonalExpenseTracker";
// import Login from './components/user-management/Login';
// import Signup from './components/user-management/Signup';
// import PinBoard from './components/PinBoard';
// import NewsPocket from './components/NewsPocket';
// // import LunchAdmin from './components/lunch-order/LunchAdmin';
// import LunchOrder from './components/lunch-order/LunchOrder';
// import ServiceTicket from './components/service-center/components/ServiceTicket';
// import PrivateRoutes from './components/private-route/PrivateRoutes';
// import HabitTracker from './components/growth-enabler/objective-tracker/HabitTracker';
// import RoleAccess from './components/private-route/RoleAccess';
// import AdminPanel from './components/admin-corner/AdminPanel';
// import NotFoundPage from './components/private-route/NotFoundPage';
// import ServiceBoard from './components/service-center/ServiceBoard';
// import MainProfile from './components/growth-enabler/profile/ProfileMain';
// import GrowthTracker from './components/growth-diary/GrowthTracker';
// import Sidebar from './components/Sidebar';
// import ManagerReview from "./components/growth-diary/ManagerReview";
// import ProfileLayout from "./components/growth-enabler/profile/ProfileLayout";
// import { GlobalProvider } from "./context/GlobalState";
// import PokePetGarden from "./components/growth-enabler/pokepet/PokePetGarden";
// import LeaveTracker from "./components/leave-tracker/LeaveTracker";

// const App: React.FC = () => {
//   const [sidebarWidth, setSidebarWidth] = useState<string>("5rem"); // Default width when collapsed
//   const handleSidebarToggle = (width: string) => {
//     console.log("Sidebar toggled. Current width:", width);
//     setSidebarWidth(width);
//   };

//   const location = useLocation();
//   const login = Boolean(localStorage.getItem('isLoggedIn'));
//   const userRole = localStorage.getItem('userRole') || '';

//   useEffect(() => {
//     setSidebarWidth("5rem"); // Reset to collapsed width on navigation
//   }, [location]);

//   return (
//     <GlobalProvider>
//       <div className="app-container">
//         {login && <Sidebar onToggle={handleSidebarToggle} />}
//         <main className="main-content" style={{ marginLeft: login ? sidebarWidth : 0 }}>
//           <Routes>
//             <Route path="/" element={login ? <PinBoard /> : <Login />} />
//             <Route path="/signup" element={<Signup />} />
//             <Route element={<PrivateRoutes isAuth={login} />}>
//               <Route path="/not-found" element={<NotFoundPage />} />
//               <Route path="/pin-board" element={<PinBoard />} />
//               <Route path="/member-view" element={<ProfileLayout />}>
//                 <Route path="profile" element={<MainProfile />} />
//                 <Route path="tickets" element={<ServiceTicket />} />
//                 <Route path="poket-pet" element={<PokePetGarden />} />
//                 <Route path="habit" element={<HabitTracker />} />
//                 <Route path="time-off" element={<LeaveTracker />} />
//               </Route>
//               <Route path="/service-center/lunch-order" element={<LunchOrder />} />
//               <Route path="/expense-tracker/team" element={<ExpenseTrackerApp isAdmin={userRole} />} />
//               <Route path="/expense-tracker/personal" element={<PersonalExpenseTrackerApp isAdmin={userRole} />} />
//               <Route path="/growth-enabler/diary" element={<GrowthTracker />} />
//               <Route element={<RoleAccess isAdmin={userRole} />}>
//                 <Route path="/growth-enabler/diary-manager-review" element={<ManagerReview />} />
//                 {/* <Route path="/service-center/lunch-admin" element={<LunchAdmin />} /> */}
//                 <Route path="/service-center/service-board" element={<ServiceBoard />} />
//                 <Route path='/news-pocket' element={<NewsPocket />} />
//                 <Route path='/admin-panel' element={<AdminPanel />} />
//               </Route>
//             </Route>
//           </Routes>
//         </main>
//       </div>
//     </GlobalProvider>
//   );
// }

// export default App;