import React from 'react';
import { useOutletContext } from 'react-router-dom';
import PokePet from './PokePet';
// ALL-GEN POKEMON 2-STEPS | EVOLUTION: BEAT OLD RECORD
import objectiveEgg from '../../../assets/eggs/Lucky_Egg.png';
// STARTING POKEMON | HATCH: TOP PERFORMERS | EVOLUTION: AFTER EVERY YEAR
import topPerformEgg from '../../../assets/eggs/Lucky_Egg_Golden.png';
// GEN1 POKEMON 3-STEPS | HATCH: PASS PROBATION | EVOLUTION: ATTENDANCE
import PokePetCarousel from './PokePetCarousel';
// LEGENDARY POKEMON PROMOTION
// import performanceEgg from '../../../assets/eggs/Egg_7_km.png';

const PokePetGarden = () => {

    const { userDetails } = useOutletContext();

    const objective1EggText = "This Pokemon egg will hatch as soon as you complete your objective!";
    const objective1PetText = "You got me by accomplishing your objective! Well done!!";

    const topPerformEggText = "For top performance";
    const topPerformPetText = "For top performance";

  return (
    <div style={{display: 'block', justifyContent: 'space-between'}}>
        <div className='pet-section'>
          <PokePet petId={userDetails?.pokepet?.objective1PetId} eggText={objective1EggText} petText={objective1PetText} eggImg={objectiveEgg}/>
        </div>
        <div className='pet-section'>
          <PokePet petId={userDetails?.pokepet?.promotionPetId} eggText={topPerformEggText} petText={topPerformPetText} eggImg={topPerformEgg}/>
        </div>
        <PokePetCarousel userDetails={userDetails}/>
    </div>
  )
}

export default PokePetGarden
