import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { io } from "socket.io-client";

const socket = io('http://localhost:3001');

const TeamLeaveRequests = ({ teamId }) => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const { userDetails } = useOutletContext();

  useEffect(() => {
    fetchLeaveRequests();
    
    // ✅ Listen for new leave requests (real-time update)
    socket.on("sync-leave-request", (newLeaveRequest) => {
      console.log("📢 New leave request received:", newLeaveRequest);
      setLeaveRequests((prev) => [newLeaveRequest, ...prev]);
    });

    // ✅ Listen for leave status updates (approved/rejected)
    socket.on("sync-leave-status", (updatedLeaveRequest) => {
      console.log("📢 Leave request updated:", updatedLeaveRequest);
      setLeaveRequests((prev) =>
        prev.map((req) =>
          req._id === updatedLeaveRequest._id ? updatedLeaveRequest : req
        )
      );
    });

    return () => {
      socket.off("sync-leave-request");
      socket.off("sync-leave-status");
    };
  }, [teamId]);

  const fetchLeaveRequests = async () => {
    try {
        setLoading(true);
        const token = `${userDetails.email}+${userDetails.password}`; // Get manager token
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        // ✅ Fetch leave requests for the manager’s organization and team
        const response = await axios.get(`/api/v1/leaves/org/${userDetails.org}/team/${userDetails.team}`, config);

        const sortedActiveRequests = response.data.filter(request => request.status !== "Canceled").sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt) 
        );;

        setLeaveRequests(sortedActiveRequests);
        } catch (error) {
            setError("Failed to fetch leave requests.");
            console.error("Error fetching leave requests:", error);
        } finally {
            setLoading(false);
        }
    };

  const updateLeaveStatus = async (requestId, newStatus) => {
    try {
      const token = `${userDetails.email}+${userDetails.password}`; // Get manager token
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      const response = await axios.put(`/api/v1/leaves/${requestId}/status`, {
        status: newStatus,
        approvedBy: userDetails._id, // Pass manager ID
      }, config);

      // ✅ Update UI after approval/rejection
      setLeaveRequests((prevRequests) =>
        prevRequests.map((req) =>
          req._id === requestId
          ? { 
              ...req,
              status: newStatus,
              approvedBy: { firstName: userDetails.firstName, lastName: userDetails.lastName },
              approvedAt: new Date().toISOString() } : req
        )
      );

      alert(`Leave request ${newStatus.toLowerCase()} successfully!`);
    } catch (error) {
      console.error("Error updating leave status:", error);
      alert("Failed to update leave request status.");
    }
  };

  return (
    <div className="leave-requests-container">
      <h2>Team Leave Requests</h2>
      {loading && <p>Loading leave requests...</p>}
      {error && <p className="error-message">{error}</p>}

      <div className="leave-table-container">
        <table className="leave-table">
          <thead>
            <tr>
              <th>Employee</th>
              <th>Leave Type</th>
              <th>Dates</th>
              <th>Reason</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {leaveRequests.length > 0 ? (
              leaveRequests.map((request) => (
                <tr key={request._id}>
                  <td>{request.user?.firstName} {request.user?.lastName}</td>
                  <td>{request.leaveType}</td>
                  <td>
                    {request.leaveDates.map((date, index) => (
                      <div key={index}>
                        {new Date(date.date).toLocaleDateString()} {date.isHalfDay ? "(Half-day)" : ""}
                      </div>
                    ))}
                  </td>
                  <td>{request.reason || "N/A"}</td>
                  <td className={`status-${request.status.toLowerCase()}`}>
                    {request.status}
                  </td>
                  <td>
                    {request.status === "Pending" ? (
                      <>
                        <button className="approve-btn" onClick={() => updateLeaveStatus(request._id, "Approved")}>
                          Approve
                        </button>
                        <button className="reject-btn" onClick={() => updateLeaveStatus(request._id, "Rejected")}>
                          Reject
                        </button>
                      </>
                    ) : (
                        <span>
                            {request.status} by {request.approvedBy?.firstName} {request.approvedBy?.lastName} <br />
                            <small>on {request.approvedAt ? new Date(request.approvedAt).toLocaleDateString() : "N/A"}</small>
                        </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No leave requests found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamLeaveRequests;