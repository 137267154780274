import React from 'react';

import CakeIcon from '@mui/icons-material/Cake';
import PlaceIcon from '@mui/icons-material/Place';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ProfileMainEditByAdmin from './ProfileMainEditByAdmin';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const ProfileMainEdit = ({userRole, state, setState}) => {

    console.log(state);

    const formatDate = (str) => {
        return str.slice(0, 10)
    }

    return (
        <form>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                <b style={{fontSize: '2rem'}}>{state?.firstName} {state?.lastName}</b>
            </div>
            <br/>
            <h3 style={{marginTop: '1rem', width: '100%'}}>Contact</h3>
            <div className='profile-part'>
                <PhoneIphoneIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Mobile
                    <input type='text' id='mobile' defaultValue={state?.contact?.mobile} onChange={(e) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        contact: { ...prevState.contact, mobile: e.target.value},
                                    }));
                                }}
                        placeholder={state?.contact?.mobile}
                    />
                </span>
                <ContactMailIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Personal Email
                    <input type='text' id='mobile' defaultValue={state?.contact?.personalEmail} onChange={(e) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        contact: { ...prevState.contact, personalEmail: e.target.value},
                                        }));
                                    }}
                        placeholder={state?.contact?.personalEmail} />
                </span>
            </div>
            <br/>
            <h3 style={{marginTop: '1rem', width: '100%'}}>Information for Contract</h3>
            <div className='profile-part'>
                <CakeIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Date of birth
                    <br/>
                    <input type='date' id='dob' defaultValue={formatDate(state?.personalInfo?.dob)}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                personalInfo: {...prevState.personalInfo, dob: e.target.value},
                            }));
                        }}
                    />
                </span>
                <PersonPinCircleIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Current Address
                    <input type='text' id='current-address' defaultValue={state?.personalInfo?.currentAddress}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                personalInfo: {...prevState.personalInfo, currentAddress: e.target.value},
                            }));
                        }} 
                        placeholder={state?.personalInfo?.currentAddress} />
                </span>
            </div>
            <div className='profile-part'>
                <RecentActorsIcon className='icon-container'/>
                <span className='profile-span-50'>
                    ID Card Number
                    <input type='text' id='id-card-no'  defaultValue={state?.personalInfo?.idCardNo}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                personalInfo: {...prevState.personalInfo, idCardNo: e.target.value},
                            }));
                        }} 
                        placeholder={state?.personalInfo?.idCardNo} />
                </span>
                <PlaceIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Address on ID card
                    <input type='text' id='id-card-address'   defaultValue={state?.personalInfo?.idCardAddress}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                personalInfo: {...prevState.personalInfo, idCardAddress: e.target.value},
                            }));
                        }} 
                        placeholder={state?.personalInfo?.idCardAddress} />
                </span>
            </div>
            <div className='profile-part'>
                <CalendarMonthIcon className='icon-container'/>
                <span className='profile-span-50'>
                    ID Card Issuance Date
                    <input type='date' id='id-card-date'  defaultValue={formatDate(state?.personalInfo?.idCardDate)}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                personalInfo: {...prevState.personalInfo, idCardDate: e.target.value},
                            }));
                        }} 
                        placeholder={state?.personalInfo?.idCardDate} />
                </span>
                <LocationCityIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Hometown on ID card
                    <input type='text' id='id-card-hometown'   defaultValue={state?.personalInfo?.idCardLocation}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                personalInfo: {...prevState.personalInfo, idCardLocation: e.target.value},
                            }));
                        }} 
                        placeholder={state?.personalInfo?.idCardLocation} />
                </span>
            </div>
            <br/>
            {state.type === 'admin' | userRole === 'admin' ? <ProfileMainEditByAdmin state={state} setState={setState} userRole={state.type === "admin" ? state.type : userRole} /> : ''}
        </div>
        </form>
    )
}

export default ProfileMainEdit