import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { timeFormatted } from '../../utils/DateFormat';

const SubmissionsList = (isInputFormOpen) => {
  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);

  console.log(isInputFormOpen);

  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {

    const token = `${userObj.email}+${userObj.password}`
    const fetchSubmissions = async () => {
      const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
      }
      await axios.get(
        '/api/v1/growth-submissions/', config
        ).then((res) => {
          const arr = res.data;
          const filteredArr = arr.filter( item => item.userId === userObj._id )
          setSubmissions(filteredArr.reverse());
        });

    };
    fetchSubmissions();
  }, []);

  const handleFeedback = async (id, comment, thumbsUp) => {
    await axios.put(`/api/v1/growth-submissions`, { comment, thumbsUp });
    setSubmissions((prev) =>
      prev.map((submission) =>
        submission._id === id ? { ...submission, SubmissionL: { comment, thumbsUp } } : submission
      )
    );
  };

  return (
    <div className={`${isInputFormOpen.isInputFormOpen === true ? 'container-narrow' : 'list-container'}`}>
        <h2 style={{width: '100%'}}>Growth Tracker</h2>
        <h2 style={{width: '100%'}}>Submissions History</h2>
        {submissions.map((submission) => (
            <div
              key={submission._id}
              className={`growth-card ${
                submission.accomplishmentChoice ===
                "I accomplished major objectives this week, meeting deadlines and maintaining high quality standards, which significantly contributed to the team's goals!"
                  ? "green-card"
                  : submission.accomplishmentChoice ===
                    "Many tasks were delayed, and several deliverables were not accepted."
                  ? "red-card"
                  : "yellow-card"
              }`}
            >
            <h3><strong>Date:</strong>{timeFormatted(submission.createdAt)}</h3>
            <p>
              <strong>Accomplishment:</strong>
              <br/><br/> {submission.accomplishmentChoice}
            </p>
            <p>
              <br/>
              <strong>Details:</strong>
              <br/><br/> {submission.details}
            </p>
            <p>
              <br/>
              <strong>Learnings:</strong>
              <br/><br/> {submission.newLearnings}
            </p>
            <div>
                {submission.SubmissionL ? (
                <div>
                    <p><strong>Feedback:</strong> {submission.SubmissionL.comment}</p>
                    <p><strong>Thumbs Up:</strong> {submission.SubmissionL.thumbsUp ? 'Yes' : 'No'}</p>
                </div>
                ) : (
                <div>
                    <textarea
                    placeholder="Comment"
                    onChange={(e) =>
                        handleFeedback(submission._id, e.target.value, submission.SubmissionL?.thumbsUp || false)
                    }
                    />
                    <button onClick={() => handleFeedback(submission._id, 'Great work!', true)}>Thumbs Up</button>
                </div>
                )}
            </div>
            </div>
        ))}
    </div>
  );
};

export default SubmissionsList;