import React from 'react'

function Logout() {
    
    function logout (e) {
        window.localStorage.clear();
        window.location.reload(false);
        // alert('You have signed out. Please log in again.');
    }
  
    return (
        <div className='logout'>
            <button className="logout-btn" title='Logout' onClick={logout}>
                <span className="material-symbols-rounded">
                    {"logout"}
                </span>
            </button>
        </div>
        // <button className="logout-button" onClick={logout}>
        //     <span className="nav-icon material-symbols-rounded">logout</span>
        //     <span className="nav-label">Logout</span>
        // </button>
    );
};

export default Logout
