import React, { useState, useEffect, useRef } from "react";
import "./Sidebar.css";
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ onToggle }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const sidebarRef = useRef(null);
  const location = useLocation();

  const collapsedSidebarHeight = "132px"; // Height in mobile view (collapsed)
  const fullSidebarHeight = "100vh"; // Height in larger screen

  // Toggle the collapsed state of the sidebar
  const toggleSidebar = () => {
      const newCollapsedState = !isCollapsed;
      setIsCollapsed(newCollapsedState);
  
      // Notify parent component about the change
      onToggle(newCollapsedState ? "5rem" : "270px");
    };

  // Toggle the menu-active state and adjust height dynamically
  const toggleMenu = () => {
    if (sidebarRef.current) {
      const height = isMenuActive
        ? collapsedSidebarHeight
        : `${sidebarRef.current.scrollHeight}px`;
      sidebarRef.current.style.height = height;
    }
    setIsMenuActive((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !isCollapsed
      ) {
        setIsCollapsed(true); // Collapse the sidebar
        setIsMenuActive(false); // Close the menu if active
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCollapsed]);

  useEffect(() => {
    const adjustSidebarHeight = () => {
      if (sidebarRef.current) {
        if (window.innerWidth >= 480) {
          // Full height for larger screens
          sidebarRef.current.style.height = fullSidebarHeight;
        } else {
          // Collapsed or menu-active height for smaller screens
          sidebarRef.current.style.height = isMenuActive
            ? `${sidebarRef.current.scrollHeight}px`
            : collapsedSidebarHeight;
        }
      }
    };
  
    // Call the function on initial render
    adjustSidebarHeight();
  
    // Add event listener for resize
    window.addEventListener("resize", adjustSidebarHeight);
    return () => window.removeEventListener("resize", adjustSidebarHeight);
  }, [isMenuActive]);

  useEffect(() => {
    setIsCollapsed(true); // Collapse sidebar on navigation
    setIsMenuActive(false); // Ensure menu is also closed
  }, [location]);

  const primaryNavItems = [
    // { icon: "dashboard", label: "Dashboard", path: "/dashboard" },
    // { icon: "calendar_today", label: "Calendar", path: "/calendar" },
    // { icon: "notifications", label: "Notifications", path: "/notifications" },
    // { icon: "group", label: "Team", path: "/team" },
    // { icon: "insert_chart", label: "Analytics", path: "/analytics" },
    // { icon: "star", label: "Bookmarks", path: "/bookmarks" },
    // { icon: "home_app_logo", label: "Pinboard", path: "/pin-board" },
    { icon: "star", label: "Welcome!", path: "/pin-board" },
    // { icon: "edit_document", label: "Ticket", path: "/service-center/tickets" },
    { icon: "trending_up", label: "Growth Diary", path: "/growth-enabler/diary" },
    // { icon: "event_available", label: "Habits", path: "/growth-enabler/habit" },
  ];

  const secondaryNavItems = [
    { icon: "view_kanban", label: "Service Panel", path: "/service-center/service-board" },
    { icon: "money_bag", label: "Team Finance", path: "/expense-tracker/team" },
    { icon: "assignment", label: "Manager Panel", path: "/manager-panel" },
    { icon: "manage_accounts", label: "Admin Panel", path: "/admin-panel" },

  ];

  function logout (e) {
    window.localStorage.clear();
    window.location.reload(false);
    // alert('You have signed out. Please log in again.');
  }

  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    backgroundColor: isHovered ? "#fff" : "transparent",
    border: "none",
    width: "fit-content",
    cursor: "pointer",
  };

  return (
    <aside
      ref={sidebarRef}
      className={`sidebar ${isCollapsed ? "collapsed" : ""} ${
        isMenuActive ? "menu-active" : ""
      }`}
    >
      <header className="sidebar-header">
        <a href="/member-view/profile" className="header-logo">
          <img src="https://img.icons8.com/bubbles/100/000000/user.png" alt="Company Logo" />
        </a>
        <button className="toggler sidebar-toggler" onClick={toggleSidebar}>
          <span className="material-symbols-rounded">
            {isCollapsed ? "menu" : "close"}
          </span>
        </button>
        <button className="toggler menu-toggler" onClick={toggleMenu}>
          <span className="material-symbols-rounded">
            {isMenuActive ? "close" : "menu"}
          </span>
        </button>
      </header>
      <nav className="sidebar-nav">
        {/* Primary top nav */}
        <ul className="nav-list primary-nav">
            {primaryNavItems.map((item, index) => (
                <li key={index} className="nav-item">
                <Link to={item.path} className="nav-link">
                    <span className="nav-icon material-symbols-rounded">
                    {item.icon}
                    </span>
                    <span className="nav-label">{item.label}</span>
                </Link>
                <span className="nav-tooltip">{item.label}</span>
                </li>
            ))}
        </ul>
        {/* Secondary bottom nav */}
        <ul className="nav-list secondary-nav">
            {secondaryNavItems.map((item, index) => (
                <li key={index} className="nav-item">
                <Link to={item.path} className="nav-link">
                    <span className="nav-icon material-symbols-rounded">
                    {item.icon}
                    </span>
                    <span className="nav-label">{item.label}</span>
                </Link>
                <span className="nav-tooltip">{item.label}</span>
                </li>
            ))}
          <li className="nav-item">
            <button className="nav-link"
              style={buttonStyle}
              onMouseEnter={() => setIsHovered(true)} // Trigger hover state
              onMouseLeave={() => setIsHovered(false)} // Reset hover state
              title='Logout'
              onClick={logout}
            >
                <span className="nav-icon material-symbols-rounded">
                    {"logout"}
                </span>
                <span className="nav-tooltip">Logout</span>
            </button>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;