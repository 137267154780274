import React, { useState } from 'react';
import UserList from '../user-management/UserList';

const AdminPanel = ({user, userRole}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Move state to AdminPanel
  console.log(user);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'start', margin: '2rem' }}>
      <div className={`${isSidebarOpen === true ? 'container-narrow' : 'list-container'}`}>
          <h2 style={{ textAlign: "left", width: '100%', margin: '1rem auto', textAlign: 'center' }}>Members List</h2>
          <UserList isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} user={user} userRole={userRole} />
      </div>
    </div>
  );
};

export default AdminPanel;

// import React, { useState, useContext } from "react";
// import UserList from "../user-management/UserList";
// import { GlobalContext } from "../../context/GlobalState";

// const AdminPanel: React.FC = () => {
//   const { users } = useContext(GlobalContext);
//   const [searchTerm, setSearchTerm] = useState<string>("");
//   const [selectedRole, setSelectedRole] = useState<string>("All");

//   // Handle search input
//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Handle role filter
//   const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     setSelectedRole(event.target.value);
//   };

//   // Filter users based on search and role selection
//   const filteredUsers = users.filter(user => 
//     user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
//   ).filter(user => selectedRole === "All" || user.type === selectedRole);

//   return (
//     <div className="admin-panel">
//       <h1>Admin Panel</h1>
      
//       {/* Search and Filter UI */}
//       <div className="controls">
//         <input 
//           type="text" 
//           placeholder="Search users..." 
//           value={searchTerm}
//           onChange={handleSearchChange}
//         />
//         <select value={selectedRole} onChange={handleRoleChange}>
//           <option value="All">All Users</option>
//           <option value="Admin">Admins</option>
//           <option value="Manager">Managers</option>
//           <option value="User">Users</option>
//         </select>
//       </div>

//       {/* Pass filtered users to UserList */}
//       <UserList users={filteredUsers} />
//     </div>
//   );
// };

// export default AdminPanel;