import React from 'react';
import { timeFormatted } from '../../../utils/DateFormat';

import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedIcon from '@mui/icons-material/Verified';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Skeleton } from '@mui/material';

const ProfileSummary = (loggedInUser) => {

  return (
    <div className = 'photo-background'>
          { loggedInUser ? (
            <div className='summary-container'>
              <img src="https://img.icons8.com/bubbles/100/000000/user.png" alt="User-Profile" style={{width: '120px'}} />
              <h4 style={{ margin: '0.5rem' }}>{loggedInUser.loggedInUser.firstName} {loggedInUser.loggedInUser.lastName}</h4>
              <h5 style={{ margin: '0.5rem' }}>
                <AssignmentIndIcon className='summary-icon-container'/> {loggedInUser.loggedInUser?.workInfo?.designation ? loggedInUser.loggedInUser?.workInfo?.designation : 'N/A'} | {loggedInUser.loggedInUser?.workInfo?.role ? loggedInUser.loggedInUser?.workInfo?.role : 'N/A'}
                <br/><VerifiedIcon className='summary-icon-container'/> {loggedInUser.loggedInUser?.workInfo?.anniversary ? timeFormatted(loggedInUser.loggedInUser?.workInfo?.anniversary) : 'N/A'}
                <br/><WorkIcon className='summary-icon-container'/> {loggedInUser.loggedInUser?.org ? loggedInUser.loggedInUser?.org : 'N/A'}
                <br/><BusinessIcon className='summary-icon-container'/> Company Location
              </h5>
            </div>
            ) : (<Skeleton variant="rectangular" height={240} />)
          }
    </div>
  )
}

export default ProfileSummary
